.MobileCommercial {
    width: 100vw;

    display: flex;
    flex-direction: column;
    overflow-x: hidden;
    overflow-y: scroll;
}

.MobileCommercialIntroduction {
    height: 70vh;
    width: 100%;
    min-height: 300px;
    position: relative;
}



.MobileCommercialServices {
    width: 100%;
    height: 70vh;
}


.MobileCommercialProjects {
    width: 100%;
    height: 70vh;
}