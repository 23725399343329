
.EngagementWindow {
    width: 100%;
    height: auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 10%;
    overflow-x: hidden;
    text-align: center;
}

.EngageButton {
    height: 5%;
    padding-left: 15px;
    padding-right: 15px;
    padding-top: 5px;
    padding-bottom: 5px;
    width: auto;
    background-color: transparent;
    color: white;
    border: 1px solid white;
    border-radius: 50px;
    font-size: 1.4rem;


}

.EngageTitle {
    width: 80%;
    font-size: 2rem;
    font-weight: 350;
}

.EB-Scroll {
    width: 100%;
    height: 80px;
    overflow: hidden;
    position: relative;
}

.BrandElement {
    margin-left: var(--leftMargin);
    margin-right: 100px;

    position: absolute;
    left: var(--elementPosition);
    z-index: 1;

    display: flex;
    justify-content: center;
}

.BrandImage {
    width: 100px;
}