.DesktopWindow {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.DNavigationBar {
    position: absolute;
    top: var(--NavbarTop);
    left: 0;
    width: calc(100vw - 25px);
    height: 60px;
    background: linear-gradient(rgba(0,0,0,1) 40%, rgba(0,0,0,0));
    z-index: 15;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
}

.DNavigationBar-Logo-Container {
    display: flex;
    flex-direction: row;
    align-items: flex-end;
    margin-top: 10px;
    margin-left: 20px;
}

.DNavigationBar-Logo {
    height: 45px;
}

.DNavigationBar-Name {
    height: 40px;
}

.DNavigationBar-Menu {
    display: flex;
    flex-direction: row;
}

.DNavigationBar-Menu-Item {
    font-size: .7em;
    margin-right: 30px;
    opacity: 60%;
    cursor: pointer;
}

.DNavigationBar-Menu-Item:hover {
    opacity: 100%;
}

.Desktop-Display {
    width: 99%;
    height: 4000px;
    overflow-y: scroll;
    scroll-behavior: smooth;
    background-color: rgb(32, 31, 31);
    z-index: 10;
}

