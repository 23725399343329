.ServicesSection {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 80px;
}

.ServicesInnerWindow {
    width: 100%;
    display: flex;
    flex-direction: column;
    max-width: 400px;
}

.Services {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    align-items: center;
    justify-content: center;
}

.ServicesTitle {
    width: 100%;
    font-size: 1.9rem;
    margin-left: 5%;
}

.ServicesIntro {
    width: 90%;
    margin-left: 5%;
    margin-top: 5%;
    font-size: 1rem;
}

.ServicesImage {
    opacity: 60%;
    width: 95%;
    margin-top: 10%;
}

.ServicesSubTitle {
    width: 100%;
    font-size: 1.8rem;
    margin-bottom: 25px;
    margin-left: 10%;
    margin-top: 5px;
}

.ServicesText {
    width: 90%;
    margin-bottom: 5%;
    font-size: 1.15rem;
}

.ServicesListItem {
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    margin-left: 20%;
}

.ServicesListSymbol {
    width: 15px;
    margin-top: 3px;
    margin-right: 2px;
    color: rgb(189, 161, 1);
    opacity: 70%;
}

.ServicesListText {
    font-size: 1.1rem;
}