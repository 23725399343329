.MobileServices {
    width: 80%;
    margin: auto;
    margin-top: 30px;
    margin-bottom: 50px;
}

.MobileServicesTitle {
    font-size: 1.8rem;
    margin-bottom: 20px;
    text-align: center;
}

.MobileService {
    width: 100%;
    margin-top: 20px;
}

.ServicesTitleSecondary {
    font-size: 1.4rem;
    margin: 0;
}

.ServicesImageSecondary {
    margin-top: 5px;
    width: 100%;
    
}

.ServicesTextSectionSecondary {
    margin-left: auto;
    margin-top: 10px;
}

.ServicesTextNormal {
    width: 100%;
    text-align: end;
    color: rgba(255, 255, 255, 0.75);
    margin: 0;
}

.ServicesTextCentered {
    width: 100%;
    text-align: center;
    color: rgba(255, 255, 255, 0.75);
    margin: 0;
}

.ServicesTextReverse{
    width: 100%;
    color: rgba(255, 255, 255, 0.75);
    margin: 0;
}


