
.FooterSection {
    width: 100%;
    margin-top: 60px;
    border-top: 1px solid white;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.FooterKyson {
    width: 90%;
    margin-top: 25px;
}

.FooterLogo {
    width: 25%;
}

.FooterLogoText {
    width: 38%;
    margin-left: 5px;
}


.FooterConnectTitle {
    width: 100%;
    margin-left: 10%;
    color: rgb(189, 161, 1);
    margin-top: 30px;
    margin-bottom: 20px;
}

.FooterConnectText {
    width: 100%;
    margin-left: 10%;
    font-size: .9rem;
}

.FooterConnectLink {
    margin-top: 10px;
    align-self: flex-end;
    margin-right: 20px;
    text-align: end;
    font-size: 1.1rem;
    color: white;
    border-radius: 40px;
    border: 1px solid white;
    padding: 5px;
    padding-left: 15px;
    padding-right: 15px;
}

.FooterSubscribeTitle {
    width: 100%;
    margin-left: 10%;
    color: rgb(189, 161, 1);
    margin-top: 15px;
    margin-bottom: 20px;
}

.FooterSubscribeText {
    width: 100%;
    margin-left: 10%;
    font-size: .9rem;
}

.FooterEmail {
    width: 100%;
    margin-left: 10%;
    margin-top: 20px;
    display: flex;
    align-content: flex-start;
    justify-content: flex-start;
}

.FooterEmailInput {
    margin-right: 5%;
    padding: 8px;
    width: 55%;
    background: transparent;
    color: white;
    font-size: 1rem;
}

.FooterEmailButton {
    border-radius: 20px;
    width: 25%;
    font-size: 1rem;
    background-color: transparent;
    color: white;
    border: 1px solid white;
}

.FooterLinks {
    width: 100%;
    margin-top:30px;
    display: flex;
    flex-direction: row;
    padding-left: 5%;
}

.FooterLink {
    font-size: 1rem;
    margin-right: auto;
    color: rgb(189, 161, 1);
}

.FooterCopy {
    width: 100%;
    margin-top: 30px;
    margin-bottom: 100px;

    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
}

.FooterCopyIcon {
    margin-right: 10px;
    margin-top: 3px;
    font-size: 15px;
}

.FooterCopyText {
    font-size: .9rem;
}