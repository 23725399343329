.MobileConnect {
    width: 100%;
    height: auto;
    display: flex;
    flex-direction: column;
    overflow-y: scroll;
    overflow-x: hidden;
}

.IntroConnect {
    position: relative;
    width: 100%;
    min-height: 350px;
}

.IntroImageFrame {
    height: 75vh;
    width: 100%;
    overflow: hidden;
    z-index: 1;

    display: flex;
    align-items: center;
    justify-content: center;
}

.IntroConnectImage {
    height: 100%;
    opacity: 40%;
}

.IntroContent {
    position: absolute;
    top: 60vh;
    left: 5vw;
    z-index: 88;
    width: 80vw;
    text-shadow: 0 0 4px black;
    max-width: 400px;
}

.IntroConnectTitle {
    font-size: 3rem;
    margin-bottom: 10vh;
}

.IntroConnectText {
    font-size: 1.1rem;
    margin-left: 5px;
    margin-bottom: 25px;
}

.ConnectFormWindow {
    width: 90vw;
    margin: auto;
    margin-top: 8vh;
    max-width: 450px;
}

.ConnectFormTitle {
    font-size: 2rem;
}

.ConnectFormText {
    font-size: 1rem;
}

.ConnectForm {
    
}

.ConnectFormFieldTitlebar {
    width: 100%;
    display: flex;
    flex-direction: row;
}

.ConnectFormFieldTitle {
    font-size: 1rem;
    color: rgb(189, 161, 1);
    opacity: 60%;
}

.ConnectFormFieldTitleSymbol {
    color: red;
    width: 8px;
    margin-left: 5px;
    margin-top: 2px;
    opacity: 40%;
}

.ConnectFormFieldInput {
    width: 100%;
}

.ConnectFormSelection {
    height: 30px;
    width: 100%;
    border-bottom: 1px solid gold;
    font-size: 1.2rem;
    margin-top: 10px;
}

.ConnectFormLong {
    width: 100%;
    background-color: transparent;
    border: none;
    border-bottom: 1px solid gold;
    height: 90px;
    color: white;
    margin-top: 5px;
    font-size: 1.2rem;
    
}

.ConnectCountryOptions {
    margin-top: 15px;
}

.ConnectFormInput {
    width: 100%;
    background-color: transparent;
    border: none;
    border-bottom: 1px solid gold;
    height: 30px;
    color: white;
    margin-top: 5px;
    font-size: 1.2rem;
}

.ConnectFormError {
    color: red;
}

.ConnectSubmitError {
    color: red;
    text-align: center;
}

.ConnectFormField {
    margin-top: 10%;
}

.ConnectSubmit {
    width: 90%;
    margin-left: 5%;
    margin-top: 50px;
    font-size: 1.4rem;
    padding: 5px;
    border-radius: 10px;
    background-color: transparent;
    border: 1px solid white;
    color: white;
}



.SubmittedWindow {
    width: 100vw;
    height: 100vh;
    overflow: hidden;
    position: relative;
}

.SubmittedImageFrame {
    width: 100%;
    height: 100%;
}

.SubmittedImage {
    height: 100%;
    opacity: 20%;
}

.SubmittedContent {
    position: absolute;
    top: 30vh;
    left: 10vw;
}

.SubmittedTitle {
    font-size: 2rem;
}

.SubmittedText {
    font-size: 1.2rem;
    margin-top: 5vh;
    width: 90%;
}

.SubmittedLink {
    font-size: 1.2rem;
    color: rgb(189, 161, 1);
    margin-top: 5vh;
}