
.IntroWindow {
    width: 100%;
    height: 70vh;
    overflow:hidden;
    max-width: 400px;
    min-height: 300px;
    align-self: center;
}

.IntroImage {
    margin-left: var(--MarginLeft);
    height: 100%;
}

.IntroText {
    color: white;
    margin-top: -100px;
    margin-left: 15px;
    text-shadow: 0 0 9px black;
}

.IntroHeader {
    font-size: 2.2rem;
    font-weight: 700;
    margin: 0;
}

.IntroSub {
    font-size: 1.4rem;
    margin: 0;
    margin-top: 10px;
}