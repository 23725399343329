.LoadingWindow {
    position: absolute;
    top:0;
    left:0;    
    height: 100vh;
    width: 100vw;
    background-color: black;
    z-index: 99;
}

.LoadingDisplay {
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    width: var(--LogoWindowWidth);
    height: var(--loadingWindowHeight);
}

.LoadingLogo {
    width:var(--LogoWidth);
    max-width: 150px;
}

.LoadingName {
    width: 80%;
    max-width: 300px;
}