.MobileAbout {
    width: 100vw;

    display: flex;
    flex-direction: column;
    overflow-x: hidden;
    overflow-y: scroll;
}

.AboutIntro {
    height: 70vh;
    width: 100%;
    min-height: 300px;
    position: relative;
}

.AboutIntroImageFrame {
    height: 100%;
    display: flex;
    justify-content: center;
}

.AboutIntroImage {
    height: 100%;
    opacity: 40%;
    min-height: 300px;
}

.AboutIntroContent {
    position: absolute;
    top: 53vh;
    left: 10vw;
    width: 80vw;
}

.AboutIntroTitle {
    font-size: 3rem;
}

.AboutIntroText {
    font-size: 3rem;
    width: 100%;
    text-align: center;
    font-family: var(--fontStyle);
}

.AboutNarrative {
    width: 100%;
    height: 600px;
}

.AboutPageSection {
    width: 90%;
    margin: auto;
    margin-top: 5vh;
}

.AboutPageTitle {
    font-size: 2rem;
    margin-bottom: 20px;
}

.AboutPageText {
    font-size: 1rem;
    margin-bottom: 20px;
}

.NarrativeBundle {
    border: 1px solid rgba(255,255,255,.3);
    margin: auto;
    margin-bottom: 40px;
    padding: 20px 5px;
    border-radius: 5px;

    max-width: 450px;
}

.StartNarrativeImage {
    width: 100%;
    opacity: 80%;
    margin-top: 20px;
}

.ValuesSection {
    width: 100%;
    margin-top: 5vh;
    height: 300px;
}





.LocationSection {
    width: 80%;
    margin: auto;
    margin-top: 10vh;
}

.locationMapSection {
    width: 95%;
    margin: auto;
}

.locationMap {
    width: 100%;
}






.BiographySection {
    width: 85%;
    margin: auto;
    margin-top: 10vh;
}

.BiographyInnerSection {
    width: 100%;

    display: flex;
    flex-direction: row;

}

.BiographyContent {
    width: 60%;

}





