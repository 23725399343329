.ProjectSection {
    width: 100%;
    color: white;
    display: flex;
    flex-direction: column;
}

.ProjectTitle {
    width: 90%;
    font-size: 1.7rem;
    margin-bottom: 5px;
    margin-left: 5%;
}

.ProjectText {
    width: 90%;
    font-size: 1rem;
    margin-left: 5%;
}

.ProjectScroll {
    overflow: hidden;
}

.ProjectScrollHeader {
    width: 100%;
    height: 30px;
    display: flex;
    flex-direction: row;
    align-items: flex-end;
    opacity: 90%;
    margin-top: 10px;
}

.ProjectChange {
    height: 30px;
    width: 15%;
    opacity: 50%;
    color: rgb(189, 161, 1);
}

.ProjectName {
    height: 100%;
    width: 70%;
    font-size: 1.3rem;
    

    display: flex;
    align-items: flex-end;
    justify-content: center;
    text-align: center;
}

.ProjectImageWindow {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 50vh;
    overflow: hidden;
}

.ProjectImageMain {
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.ProjectTray {
    padding-top: 2%;
    width: 100%;
    height: 100px;
    overflow-x: scroll;
    background-color: #141414;
    display: flex;
    flex-direction: row;
}

.ProjectImage {
    height: 100%;
}

.ProjectThumbnail {
    height: 100px;
    margin-left: 2%;
    margin-right: 2%;
}
