.AboutSection {
    display: 100%;
    margin-top: 10%;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.AboutTitle {
    width: 85%;
    font-size: 1.7rem;
    text-align: end;
}

.AboutText {
    width: 82%;
    margin-top: 20px;
    font-size: 1rem;
    text-align: end;
    margin-bottom: 10px;
}

.AboutLink {
    width: 85%;
    margin-top: 10px;
    font-size: 1.1rem;
    text-align: end;
    color: rgb(189, 161, 1);
}

.AboutKaizen {
    width: 40%;
    margin-top: 5px;
    margin-bottom: 5px;
}