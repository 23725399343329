.Desktop-Home {
    width: 100%;
    display: flex;
    flex-direction: column;
    overflow: hidden;
}

.Desktop-Intro-Engagement {
    width: 100%;
    display: flex;
    flex-direction: row;
    overflow: hidden;
}


.Desktop-Intro-Engagement-Record {
    width: var(--record-width);
    height: 100vh;
    overflow: hidden;

}

.Desktop-Intro-Engagement-Record-Image {
    height: 100%;


}

.Desktop-Intro-Engagement-Record-Text {
    margin-top: -120px;
    margin-left: 20px;

}

.Desktop-Intro-Engagement-Record-Tag {
    font-size: 1.1em;
    color: rgb(229, 229, 229);
    box-shadow: 10px 5px 5px red;
     
}

.Desktop-Intro-Engagement-Record-Title {
    font-size: 1em;
}

.Desktop-Intro-Engagement-Record-Description {
    font-size: 0.8em;
    color: rgb(229, 229, 229);
}

.Desktop-Engagement {
    height: 900px;
}

.Desktop-Engagement-Connect {
    width: 100%;
    text-align: center;
}