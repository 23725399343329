.MobileLoginPage {
    width: 100vw;
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
}

.MobileLoginContent {
    width: 80%;
    Height: 60%;

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    text-align: center;
}

.MobileLoginHeader {
    height: 25%;
}

.MobileLoginTitle {
    font-size: 2rem;
}   

.MobileLoginSubtitle {
    font-size: 1.1rem;
}

.MobileLoginInputs {
    width: 80%;
}

.MobileLoginInput {
    width: 100%;
    margin-top: 30px;
    font-size: 1.1rem;
    padding: 10px 10px 0px 10px;

    background-color: transparent;
    border: none;
    border-bottom: 1px solid rgb(189, 161, 1);
    color: white;
}

.MobileLoginForgot {
    font-size: .9rem;
    color: rgb(136, 115, 0);
    margin-top: 10px;
}

.MobileLoginButton{
    font-size: 1.4rem;
    margin-top: 40px;
    background-color: rgb(130, 111, 2);
    width: 100%;
    border: 2px solid white;
    border-radius: 20px;
    color: white;
    opacity: 90%;
    padding: 5px;
}

.MobileLoginFooter{
    width: 100%;
    height: 10%;
    margin-top: 20px;
}

.MobileLoginError {
    font-size: 1rem;
    color: red;
}

.MobileLoginSuccess {
    font-size: 1.2rem;
    color: white;
}