.MobileResidential {
    width: 100vw;

    display: flex;
    flex-direction: column;
    overflow-x: hidden;
    overflow-y: scroll;
}

.MobileResidentialIntroduction {
    height: 70vh;
    width: 100%;
    min-height: 300px;
    position: relative;
}