.MobileWindow {
    display: flex;
    flex-direction: column;
    height: 100vh;
    width: 100vw;
    overflow: hidden;
}

.MobileNavbar {
    position: fixed;
    top: var(--NavbarTop);
    left: 0;
    background: linear-gradient(rgba(0,0,0,1) 40%, rgba(0,0,0,0));
    opacity: 80%;
    height: 9%;
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    text-align: center;
    z-index: 98;
}

.NavName {
    height: 100%;
    width: 33.3%;
    z-index: 99;
    display: flex;
    align-items: flex-end;
    
}

.KysonName {
    height: 55%;
    margin-left: 10px;
    margin-bottom: 10%;
}

.NavLogo {
    height: 100%;
    width: 33.3%;
    padding-top: 4%;
    z-index: 99;
}

.KysonIcon {
    height: 70%;
}

.NavMenu {
    height: 100%;
    width: 33.3%;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    z-index: 99;
    margin-right: 10px;
}



.MenuIcon {
    height: 100%;
    width: 25px;
    margin-right: 20px;
    z-index: 100;
}

.MobilePrimary {
    height: 100%;
    width: 100%;
    overflow-y: scroll;
}

.MenuWindow{
    position: fixed;
    top: 0;
    height: 100vh;
    width: 100vw;
    background: transparent;
    z-index: 98;
}

.Menu {
    width: 70%;
    height: 100%;
    background: linear-gradient(270deg, rgba(0,0,0,1) 70%, rgba(0,0,0,0));
    opacity: 80%;
    margin-left: var(--marginLeft);
    text-align: right;
    z-index: 99;
}

.MenuLink {
    opacity: 1;
    font-size: 1.7rem;
    margin-right: 35px;
    padding-top: 20px;
    z-index: 100;
    text-shadow: 0px 0px 1px black;
}

.Last-Option {
    margin-top: 80%;
}